<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1 class="">All Workflows</h1>
      <div class="btns-container">
        <VasionButton
          v-if="isWorkflowAdminUser"
          id="actions"
          name="actions"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="newWorkflow"
        >
          NEW WORKFLOW
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          v-if="tableData && tableData.Rows"
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="editWorkflowClick"
        />
        <div v-else>
          No content to display
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="vasion-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="deleteMessage"
      :sync="showDeleteDialog"
      @confirmButtonClick="deleteWorkflow()"
      @noButtonClick="showDeleteDialog = !showDeleteDialog"
    />
    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'TheAllWorkflows',
  data: function () {
    return {
      columns: {
        Values: [
            '_WorkflowID_',
            'Workflow Name',
            '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      deleteMessage: '',
      deleteWorkflowId: 0,
      deleteWorkflowName: '',
      hiddenColumns: ['_WorkflowID_'],
      tableData: {},
      showDeleteDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      workflowList: null,
    }
  },
  computed: {
    currentPageNumber() { return 1 },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    isWorkflowAdminUser() { return this.$store.getters['common/isWorkflowAdminUser'] },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    showGrid() { return true },
    totalPages() { return 1 },
  },
  async created() {
    await this.$store.dispatch('security/setEllipsisButtonConfig', 'workflow')
    this.getWorkflows()
  },
  methods: {
    buildTableData(workflowList) {
      const data = !workflowList ? [] : workflowList.map((workflow) => {
        return {
          Values: [
            workflow.iID,
            workflow.sName,
            'VasionEllipsisIcon',
          ],
          ValueType: 'String',
        }
      })

      this.tableData = {
        Columns: this.columns,
        Rows: {
          Values: data,
          ValueType: 'ApiArray`1',
        },
      }
    },
    async copyWorkflow(id) {
      if (!id || id <= 0) {
        return
      }
      const response = await this.$store.dispatch('workflow/copyWorkflow', id)
      const isCopied = !isNaN(response.Value)
      this.showDeleteDialog = false

      if (isCopied) {
        await this.getWorkflows()
        this.editWorkflow(response.Value)
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = response.Value
        this.snackbarImage = false
        this.showSnackbar = true;
      }
    },
    async deleteWorkflow() {
        const response = await this.$store.dispatch('workflow/deleteWorkflow', this.deleteWorkflowId)
        const isDeleted = (response.Value === '')
        this.showDeleteDialog = false

        this.snackbarTitle = !isDeleted ? 'Error' : `Workflow: ${this.deleteWorkflowName} Deleted Successfully`
        this.snackbarSubTitle = !isDeleted ? response.Value : 'The Workflow has been removed'
        this.snackbarImage = isDeleted
        this.showSnackbar = true;
        this.getWorkflows()
    },
    async editWorkflow(selectInfo) {
      if (!selectInfo || selectInfo <= 0) {
        return
      }
      const activeNav = `configure-workflow-${selectInfo}`
      this.$store.dispatch('mainViews/changeMainNav', activeNav)
      await this.$store.dispatch('workflow/selectWorkflow', parseInt(selectInfo, 10))
      this.$router.push({ name: 'WorkflowAdmin' })
    },
    editWorkflowClick(selection) {
      // eslint-disable-next-line
      const [id, name] = selection.Values
      this.editWorkflow(id)
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-workflow-button':
            this.editWorkflow(id)
            break;
          case 'delete-workflow-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.deleteWorkflowId = id
            this.deleteWorkflowName = name
            this.showDeleteDialog = true
            break;
          case 'copy-workflow-button':
            this.copyWorkflow(id)
            break;
          default:
            break;
        }
      }
    },
    async getWorkflows() {
      this.workflowList = await this.$store.dispatch('workflow/getWorkflowAdminList')
      this.buildTableData(this.workflowList)
    },
    iconClick(options) {
        const { index } = options
        const [id, name] = options.item.Values
        switch (index) {
        case 2:
            this.editWorkflow(id)
            break;
        case 3:
            this.deleteMessage = `Are you sure you want to delete ${name} ?`
            this.showDeleteDialog = true
            this.deleteWorkflowId = id
            this.deleteWorkflowName = name
            break;
        default:
            console.warn('Icon Click action not found')
            break;
        }
    },
    async newWorkflow() {
      await this.$store.dispatch('workflow/selectWorkflow', 0)
      this.$router.push({ name: 'WorkflowAdmin' })
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
